import { render, staticRenderFns } from "./workflowConfigUsers.vue?vue&type=template&id=0fad972a&scoped=true"
import script from "./workflowConfigUsers.vue?vue&type=script&lang=js"
export * from "./workflowConfigUsers.vue?vue&type=script&lang=js"
import style0 from "./workflowConfigUsers.vue?vue&type=style&index=0&id=0fad972a&prod&scoped=true&lang=scss"
import style1 from "./workflowConfigUsers.vue?vue&type=style&index=1&id=0fad972a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fad972a",
  null
  
)

export default component.exports